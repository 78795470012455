<template>
  <div class="news-comment">
    <div class="news-comment__author">
      <p class="news-comment__photo" :style="`background: url('${getAuthorPhoto}') center / cover`"></p>
      <div>
        <p class="news-comment__author">
          <router-link :to="'/member_profile/' + (card.author_model.slug ? card.author_model.slug : card.author_model.id)" v-if="getIsMember">
            {{ getAuthorInitials }}
          </router-link>
          <span v-else>
            {{ getAuthorInitials }}
          </span>
        </p>
        <p class="news-comment__date">
          {{ getFormattedDate(new Date(card.create_date), true, true)}}
        </p>
      </div>
    </div>
    <blockquote class="news-comment__block">
      <div class="ckEditor-html" v-html="showFull ? ($store.getters.GET_LANG ? card.text : card.text_eng) : sliceText($store.getters.GET_LANG ? card.text : card.text_eng, 170)"></div>
      <p class="news-comment__btn" v-if="sliceActive" @click="showFull = !showFull">{{ showFull ? ($store.getters.GET_LANG ? 'Свернуть' : 'Roll up')
        : ($store.getters.GET_LANG ? 'Показать целиком' : 'Show in full') }}</p>
    </blockquote>
  </div>
</template>

<script>
import formatDate from '@/mixins/formatDate'
export default {
  name: 'CommentCard',
  props: {
    card: {
      type: Object
    },
    listView: {
      type: Boolean,
      default: false
    },
    showFull: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    formatDate
  ],
  data () {
    return {
      textMore: 'Посмотреть весь текст',
      fullText: false,
      sliceActive: false
    }
  },
  computed: {
    getAuthorPhoto () {
      if (this.card.author_model && this.card.author_model.photo) {
        return this.$store.getters.getUrlApi + this.card.author_model.photo.slice(1)
      } else {
        return '/assets/icons/profile_photo.svg'
      }
    },

    getAuthorInitials () {
      return (this.$store.getters.GET_LANG ? this.card.author_model.first_name : this.card.author_model.first_name_eng) + ' ' +
        (this.$store.getters.GET_LANG ? this.card.author_model.last_name : this.card.author_model.last_name_eng)
    },

    getIsMember () {
      return !this.card.author_model.password
    }

  },
  methods: {
    sliceText (text, value) {
      if (text.length > value) {
        this.sliceActive = true
        var str = text.slice(0, value)
        var a = str.split(' ')
        a.splice(a.length - 1, 1)
        return a.join(' ') + ' ... '
      } else return text
    }
  }
}
</script>

<style scoped lang="scss">
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
.news-comment {
  padding: 1.25rem 1.875rem;
  width: 100%;
  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);

  border: 1px solid #EBF3FF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 1.875rem;

  &__photo {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 100%;
  }

  &__author{
    display: flex;
    font-weight: bold;
    font-size: 1rem;
    line-height: 150%;

    margin-bottom: 0.35rem;

    * {
      color: #1F3245 !important;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1.25rem;
    }
  }

  &__date {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 150%;
    color: rgba(23, 23, 23, 0.8);
  }

  blockquote {
    margin-top: 0.75rem;
  }
  ::v-deep &__text{
    p {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      line-height: 157%;
      color: rgba(23, 23, 23, 0.8);
    }
  }

  &__block {
    position: relative;
    padding: 1.25rem 3rem;

    font-size: .875rem;
    font-style: italic;
    font-weight: normal;
    line-height: 171%;

    color: #5E686C;
    background: #F1F4FA;
    border-radius: 8px;
  }
  &__block:before,
  &__block:after {
    position: absolute;
    background: url('../../assets/icons/quotes.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 0.7rem;
    height: 0.7rem;
  }
  &__block:before {
    content: '';
    left: 1.3rem;
    top: 1.6rem;
  }
  &__block:after {
    content: '';
    right: 1.3rem;
    bottom: 1.6rem;
  }
  &__btn {
    cursor: pointer;
    color: #246CB7;
  }
}
@media screen and (max-width: 420px){
  .news-comment {
    padding: 1.25rem;

      &__block {
        padding: 0.5rem 2rem;
        font-size: .75rem;
        line-height: 150%;

        &:before {
          left: 0.75rem;
          top: 0.75rem;
        }
        &:after {
          right: 0.75rem;
          bottom: 0.75rem;
        }
      }
  }
}
</style>
