<template>
  <div class="ckEditor-html" v-html="$store.getters.GET_LANG ? contentBlock.text : contentBlock.text_eng">
  </div>
</template>

<script>
export default {
  name: 'TextContentBlock',
  props: {
    contentBlock: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
    p {
      margin: 1.25rem 0;
    }
    iframe{
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 768px){
  .ckEditor-html {
  ::v-deep {
    li, p{
      font-size: min(1rem, 12);
    }
  }
}
}
</style>
