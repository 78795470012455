import { render, staticRenderFns } from "./TwoPicContentBlock.vue?vue&type=template&id=54935278&scoped=true&"
import script from "./TwoPicContentBlock.vue?vue&type=script&lang=js&"
export * from "./TwoPicContentBlock.vue?vue&type=script&lang=js&"
import style0 from "./TwoPicContentBlock.vue?vue&type=style&index=0&id=54935278&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54935278",
  null
  
)

export default component.exports