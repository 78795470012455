<template>
  <div class="content__container">
    <img :src="contentBlock.image && contentBlock.image.length ? contentBlock.image[0] : ''"
    @click="setLightBox(contentBlock.image && contentBlock.image.length ? contentBlock.image[0] : null)">

    <div v-html="$store.getters.GET_LANG ? contentBlock.text : contentBlock.text_eng" class="html__content">
    </div>

    <img :src="contentBlock.image && contentBlock.image.length ? contentBlock.image[1] : ''" alt=""
         @click="setLightBox(contentBlock.image && contentBlock.image.length ? contentBlock.image[1] : null)">
  </div>
</template>

<script>

export default {
  name: 'TwoPicContentBlock',

  props: {
    contentBlock: {
      type: Object,
      required: true
    }
  },

  methods: {
    setLightBox (image) {
      this.$store.commit('setLightBoxImage', image)
    }
  }
}
</script>

<style scoped lang="scss">
  .content__container {
    display: flex;
    justify-content: space-between;

    > .html__content {
      width: 23.75rem;
    }

    > img {
      width: 15rem;
      height: 11.25rem;
      object-fit: cover;
      object-position: center;
      border-radius: .5rem;
    }
  }
</style>
