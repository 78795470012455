<template>
  <div class="content__container">
    <div v-html="$store.getters.GET_LANG ? contentBlock.text : contentBlock.text_eng" class="html__content">
    </div>

    <img :src="contentBlock.image && contentBlock.image.length ? contentBlock.image[0] : ''" @click="setLightBox">
  </div>
</template>

<script>

export default {
  name: 'RightPicContentBlock',

  props: {
    contentBlock: {
      type: Object,
      required: true
    }
  },

  methods: {
    setLightBox () {
      if (this.contentBlock.image && this.contentBlock.image.length) {
        this.$store.commit('setLightBoxImage', this.contentBlock.image[0])
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .content__container {
    display: flex;
    justify-content: space-between;

    > .html__content {
      width: 38.75rem;
    }

    > img {
      width: 15rem;
      height: 11.25rem;
      object-fit: cover;
      object-position: center;
      border-radius: .5rem;
    }
  }
</style>
