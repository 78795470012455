<template>
  <div class="social-network__icons">
    <div class="ya-share2"
         data-curtain
         data-shape="round"
         :data-title="content.title ? content.title : ''"
         :data-image="content.preview_photo ? content.preview_photo : ''"
         :data-description="content.description ? content.description : ''"
         data-services="vkontakte,facebook,telegram,twitter">
    </div>

    <p>
      {{$store.getters.GET_LANG ? 'Вы можете поделиться новостью в соцсетях' : 'You can share the news in social networks'}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SocialNetworkIcons',

  props: {
    content: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  methods: {
    addMetaTag (key, value) {
      const tag = document.createElement('meta')
      tag.setAttribute('name', key)
      tag.setAttribute('content', value)
      document.head.appendChild(tag)
    },

    setMetaTags () {
      if ('title' in this.content) {
        this.addMetaTag('og:title', this.content.title)
      }
      if ('description' in this.content) {
        this.addMetaTag('og:description', this.content.description)
      }
      if ('preview_photo' in this.content) {
        this.addMetaTag('og:image', this.content.preview_photo)
      }

      this.addMetaTag('og:url', window.location.href)
    }
  },

  mounted () {
    const shareScript = document.createElement('script')
    shareScript.setAttribute('src', 'https://yastatic.net/share2/share.js')
    document.head.appendChild(shareScript)
  }
}
</script>

<style scoped lang="scss">

  .social-network__icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2rem;
    border-top: 1px solid #D5D6D9;

    .ya-share2 {
      margin-right: .9375rem;
    }

    p {
      font-weight: 600;
      font-size: .8125rem;
      line-height: 1.25rem;
      color: #5E686C;
    }
  }

</style>
